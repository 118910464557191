// src/components/TeamTypesModal.jsx

import React, { useContext, useState, useEffect } from 'react';
import './TeamTypesModal.css';
import CustomModal from '../Common/CustomModal';
import { TeamContext } from '../../contexts/TeamContext';
import DeleteConfirmationModal from '../Common/DeleteConfirmationModal';
import ActionButton from '../Common/ActionButton';
import IconMapSelector from '../Common/IconMapSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import iconMap from '../Common/IconMap';
import { useTranslation } from 'react-i18next';

const TeamTypesModal = ({ show, onClose, showToast }) => {
  const { t } = useTranslation();
  const {
    teamTypes,
    addTeamType,
    deleteTeamType,
    editTeamType,
    fetchTeamTypes,
  } = useContext(TeamContext);

  const [localTeamTypes, setLocalTeamTypes] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [typeToDelete, setTypeToDelete] = useState(null);

  // When modal opens, copy team types and ensure each has a roles array and an "expanded" flag.
  useEffect(() => {
    if (show) {
      const enhancedTypes = teamTypes.map((tt) => ({
        ...tt,
        roles: tt.roles ? [...tt.roles] : [],
        expanded: false, // default to collapsed
      }));
      setLocalTeamTypes([...enhancedTypes]);
    }
  }, [show, teamTypes]);

  // Toggle collapse/expand for a team type
  const handleToggleCollapse = (typeId) => {
    setLocalTeamTypes((prev) =>
      prev.map((t) =>
        t.id === typeId ? { ...t, expanded: !t.expanded } : t
      )
    );
  };

  // ADD NEW TYPE (add a blank team type)
  const handleAddNewType = () => {
    let maxOrder = 0;
    localTeamTypes.forEach((t) => {
      if (typeof t.order === 'number' && t.order > maxOrder) {
        maxOrder = t.order;
      }
    });

    const newType = {
      id: `temp-${Date.now()}`, // temporary ID
      name: '',
      icon: '',
      order: maxOrder + 1,
      // For team types, you can continue using isNew if desired.
      isNew: true,
      roles: [],
      expanded: true, // new types start expanded so roles can be added
    };

    setLocalTeamTypes((prev) => [...prev, newType]);
  };

  // DELETE (local)
  const handleDeleteType = (type) => {
    setTypeToDelete(type);
    setDeleteModalOpen(true);
  };

  const confirmDeleteType = () => {
    if (!typeToDelete) return;
    if (typeToDelete.isNew) {
      setLocalTeamTypes((prev) => prev.filter((t) => t.id !== typeToDelete.id));
    } else {
      setLocalTeamTypes((prev) =>
        prev.map((t) =>
          t.id === typeToDelete.id ? { ...t, isDeleted: true } : t
        )
      );
    }
    setDeleteModalOpen(false);
    setTypeToDelete(null);
  };

  // EDIT name/icon (local)
  const handleNameChange = (typeId, newName) => {
    setLocalTeamTypes((prev) =>
      prev.map((t) => (t.id === typeId ? { ...t, name: newName } : t))
    );
  };

  const handleIconChange = (typeId, newIcon) => {
    setLocalTeamTypes((prev) =>
      prev.map((t) => (t.id === typeId ? { ...t, icon: newIcon } : t))
    );
  };

  // ===== ROLE HANDLERS =====

  // Add a new blank role to a team type.
  // Instead of saving "isNew", calculate the next order.
  const handleAddRole = (teamTypeId) => {
    setLocalTeamTypes((prev) =>
      prev.map((t) => {
        if (t.id === teamTypeId) {
          const currentMaxOrder = (t.roles || []).reduce(
            (max, role) => Math.max(max, role.order || 0),
            0
          );
          const newRole = {
            id: `temp-role-${Date.now()}`,
            name: '',
            icon: '',
            order: currentMaxOrder + 1,
          };
          return { ...t, roles: [...(t.roles || []), newRole] };
        }
        return t;
      })
    );
  };

  // Update role name for a specific role within a team type.
  const handleRoleNameChange = (teamTypeId, roleId, newName) => {
    setLocalTeamTypes((prev) =>
      prev.map((t) => {
        if (t.id === teamTypeId) {
          const updatedRoles = t.roles.map((role) =>
            role.id === roleId ? { ...role, name: newName } : role
          );
          return { ...t, roles: updatedRoles };
        }
        return t;
      })
    );
  };

  // Update role icon for a specific role within a team type.
  const handleRoleIconChange = (teamTypeId, roleId, newIcon) => {
    setLocalTeamTypes((prev) =>
      prev.map((t) => {
        if (t.id === teamTypeId) {
          const updatedRoles = t.roles.map((role) =>
            role.id === roleId ? { ...role, icon: newIcon } : role
          );
          return { ...t, roles: updatedRoles };
        }
        return t;
      })
    );
  };

  // Delete a role from a team type.
  const handleDeleteRole = (teamTypeId, roleId) => {
    setLocalTeamTypes((prev) =>
      prev.map((t) => {
        if (t.id === teamTypeId) {
          return { ...t, roles: t.roles.filter((role) => role.id !== roleId) };
        }
        return t;
      })
    );
  };

  // Reorder roles within a team type – move up.
  const handleRoleMoveUp = (teamTypeId, roleId) => {
    setLocalTeamTypes((prev) =>
      prev.map((t) => {
        if (t.id === teamTypeId) {
          const index = t.roles.findIndex((r) => r.id === roleId);
          if (index <= 0) return t;
          const newRoles = [...t.roles];
          [newRoles[index - 1], newRoles[index]] = [newRoles[index], newRoles[index - 1]];
          // Reassign order based on new positions.
          const updatedRoles = newRoles.map((role, i) => ({ ...role, order: i + 1 }));
          return { ...t, roles: updatedRoles };
        }
        return t;
      })
    );
  };

  // Reorder roles within a team type – move down.
  const handleRoleMoveDown = (teamTypeId, roleId) => {
    setLocalTeamTypes((prev) =>
      prev.map((t) => {
        if (t.id === teamTypeId) {
          const index = t.roles.findIndex((r) => r.id === roleId);
          if (index === -1 || index === t.roles.length - 1) return t;
          const newRoles = [...t.roles];
          [newRoles[index + 1], newRoles[index]] = [newRoles[index], newRoles[index + 1]];
          const updatedRoles = newRoles.map((role, i) => ({ ...role, order: i + 1 }));
          return { ...t, roles: updatedRoles };
        }
        return t;
      })
    );
  };

  // Reorder team types (move up)
  const handleMoveUp = (type) => {
    const currentIndex = localTeamTypes.findIndex((t) => t.id === type.id);
    if (currentIndex <= 0) return;
    const newOrder = [...localTeamTypes];
    const prevType = newOrder[currentIndex - 1];
    const currentType = newOrder[currentIndex];
    const tempOrder = currentType.order;
    currentType.order = prevType.order;
    prevType.order = tempOrder;
    newOrder[currentIndex - 1] = currentType;
    newOrder[currentIndex] = prevType;
    setLocalTeamTypes(newOrder);
  };

  // Reorder team types (move down)
  const handleMoveDown = (type) => {
    const currentIndex = localTeamTypes.findIndex((t) => t.id === type.id);
    if (currentIndex >= localTeamTypes.length - 1) return;
    const newOrder = [...localTeamTypes];
    const nextType = newOrder[currentIndex + 1];
    const currentType = newOrder[currentIndex];
    const tempOrder = currentType.order;
    currentType.order = nextType.order;
    nextType.order = tempOrder;
    newOrder[currentIndex + 1] = currentType;
    newOrder[currentIndex] = nextType;
    setLocalTeamTypes(newOrder);
  };

  // SAVE CHANGES – send team type data (including roles with their order) to Firebase.
  const handleSaveChanges = async () => {
    const finalTypes = [...localTeamTypes];
    if (finalTypes.some((t) => !t.isDeleted && (!t.name || !t.name.trim()))) {
      alert(t('teamTypesModal.validationError', 'All team types must have a valid name.'));
      return;
    }
    try {
      // Delete team types marked for deletion.
      const typesToDelete = finalTypes.filter((t) => t.isDeleted && !t.isNew);
      for (const t of typesToDelete) {
        await deleteTeamType(t.id);
      }
      // Add new team types.
      const typesToAdd = finalTypes.filter((t) => t.isNew && !t.isDeleted);
      for (const t of typesToAdd) {
        await addTeamType({ 
          name: t.name.trim(), 
          icon: t.icon, 
          roles: t.roles 
        });
      }
      const originalIds = teamTypes.map((tt) => tt.id);
      // Update existing team types.
      const existingTypesToUpdate = finalTypes.filter(
        (t) =>
          !t.isNew &&
          !t.isDeleted &&
          originalIds.includes(t.id) &&
          (() => {
            const original = teamTypes.find((orig) => orig.id === t.id);
            return (
              original.name !== t.name ||
              original.icon !== t.icon ||
              original.order !== t.order ||
              JSON.stringify(original.roles || []) !== JSON.stringify(t.roles || [])
            );
          })()
      );
      for (const t of existingTypesToUpdate) {
        const updatedFields = {
          name: t.name.trim(),
          icon: t.icon,
          order: t.order,
          roles: t.roles,
        };
        await editTeamType(t.id, updatedFields);
      }
      await fetchTeamTypes();
      if (showToast) {
        showToast(t('teamTypesModal.saveSuccess'), 'success');
      }
      onClose();
    } catch (error) {
      console.error('Error saving changes:', error);
      if (showToast) {
        showToast(t('teamTypesModal.saveError', { error: error.message }), 'error');
      }
    }
  };

  return (
    <>
      <CustomModal show={show} onClose={onClose} title={t('teamTypesModal.modalTitle')}>
        <div className="team-types-modal-container">
          {localTeamTypes.filter((type) => !type.isDeleted).map((type, index) => {
            const visibleTypes = localTeamTypes.filter((t) => !t.isDeleted);
            const isFirst = index === 0;
            const isLast = index === visibleTypes.length - 1;
            return (
              <div key={type.id} className="team-types-card">
                {/* Header: team type controls */}
                <div className="team-type-header">
                  <div className="team-types-main">
                    <IconMapSelector
                      selectedIcon={type.icon || ''}
                      onChange={(val) => handleIconChange(type.id, val)}
                    />
                    <input
                      type="text"
                      className="type-name-input"
                      value={type.name}
                      onChange={(e) => handleNameChange(type.id, e.target.value)}
                      placeholder={t('teamTypesModal.teamTypeNamePlaceholder')}
                      onClick={(e) => e.stopPropagation()}
                    />
                  </div>
 
                  <div className="header-actions">
                    <ActionButton
                      onClick={() => handleMoveUp(type)}
                      icon="faArrowUp"
                      label={t('teamTypesModal.moveUpLabel', 'Up')}
                      colorType="secondary"
                      disabled={isFirst}
                    />
                    <ActionButton
                      onClick={() => handleMoveDown(type)}
                      icon="faArrowDown"
                      label={t('teamTypesModal.moveDownLabel', 'Down')}
                      colorType="secondary"
                      disabled={isLast}
                    />
                    <ActionButton
                      onClick={() => handleDeleteType(type)}
                      icon="faTrash"
                      label={t('teamTypesModal.deleteLabel', 'Delete')}
                      colorType="danger"
                    />
                  </div>
                   <button 
                    className="chevron-button" 
                    onClick={(e) => { 
                      e.stopPropagation(); 
                      handleToggleCollapse(type.id); 
                    }}
                    aria-label={type.expanded ? t('teamTypesModal.collapse', 'Collapse') : t('teamTypesModal.expand', 'Expand')}
                   >
                    <FontAwesomeIcon icon={type.expanded ? iconMap.faChevronUp : iconMap.faChevronDown} />
                  </button>
                </div>
                {type.expanded && (
                  <div className="team-roles-container">
                    {type.roles.map((role, rIndex) => (
                      <div key={role.id} className="team-role-card">
                        <IconMapSelector
                          selectedIcon={role.icon || ''}
                          onChange={(val) => handleRoleIconChange(type.id, role.id, val)}
                        />
                        <input
                          type="text"
                          className="role-name-input"
                          value={role.name}
                          onChange={(e) => handleRoleNameChange(type.id, role.id, e.target.value)}
                          placeholder={t('teamTypesModal.roleNamePlaceholder', 'Role Name')}
                        />
                        <div className="team-role-actions">
                          <ActionButton
                            onClick={() => handleRoleMoveUp(type.id, role.id)}
                            icon="faArrowUp"
                            label={t('teamTypesModal.moveUpLabel', 'Up')}
                            colorType="secondary"
                            disabled={rIndex === 0}
                          />
                          <ActionButton
                            onClick={() => handleRoleMoveDown(type.id, role.id)}
                            icon="faArrowDown"
                            label={t('teamTypesModal.moveDownLabel', 'Down')}
                            colorType="secondary"
                            disabled={rIndex === type.roles.length - 1}
                          />
                          <ActionButton
                            onClick={() => handleDeleteRole(type.id, role.id)}
                            icon="faTrash"
                            label={t('teamTypesModal.deleteLabel', 'Delete')}
                            colorType="danger"
                          />
                        </div>
                      </div>
                    ))}
                    <ActionButton
                      onClick={() => handleAddRole(type.id)}
                      icon="faPlus"
                      colorType="primary"
                      text={t('teamTypesModal.addRoleButton', 'Add Role')}
                    />
                  </div>
                )}
              </div>
            );
          })}
          <ActionButton
            onClick={handleAddNewType}
            icon="faPlus"
            colorType="primary"
            fullWidth={true}
            text={t('teamTypesModal.createNewTeamTypeButton')}
            className="add-team-type-button"
          />
        </div>
        <div className="save-button-container">
          <ActionButton
            onClick={handleSaveChanges}
            icon="faSave"
            colorType="success"
            fullWidth={true}
            text={t('teamTypesModal.saveButton')}
          />
        </div>
      </CustomModal>
      {deleteModalOpen && (
        <DeleteConfirmationModal
          show={deleteModalOpen}
          onConfirm={confirmDeleteType}
          onCancel={() => setDeleteModalOpen(false)}
          title={t('teamTypesModal.deleteModalTitle')}
          message={t('teamTypesModal.deleteModalMessage', { typeName: typeToDelete?.name || '' })}
        />
      )}
    </>
  );
};

export default TeamTypesModal;
