// src/components/Common/ToggleButton.jsx

import React from 'react';
import PropTypes from 'prop-types';
import './ToggleButton.css';

/**
 * ToggleButton
 * 
 * A reusable, generic toggle button.
 * - If isActive is true, background is var(--main-color); otherwise light gray (#ccc).
 * - icon (optional): Pass in a React element (like <FontAwesomeIcon icon={faCrown} />).
 * - onToggle: callback for click events.
 * - includes a 3D press effect on click.
 */
const ToggleButton = ({ isActive, onToggle, icon, ariaLabel }) => {
  const buttonClass = isActive
    ? 'toggle-button toggle-button--active'
    : 'toggle-button';

  return (
    <button
      type="button"
      className={buttonClass}
      onClick={onToggle}
      aria-label={ariaLabel || 'Toggle button'}
    >
      {icon && <span className="toggle-button-icon">{icon}</span>}
    </button>
  );
};

ToggleButton.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  icon: PropTypes.node,
  ariaLabel: PropTypes.string,
};

ToggleButton.defaultProps = {
  icon: null,
  ariaLabel: '',
};

export default ToggleButton;
