import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import TextField from './Fields/TextField';
import NumberField from './Fields/NumberField';
import DatePicker from './Fields/DatePicker';
import TimePicker from './Fields/TimePicker';
import ImagePicker from './Fields/ImagePicker';
import CheckboxField from './Fields/CheckboxField';
import RadioButtonField from './Fields/RadioButtonField';
import TelephoneField from './Fields/TelephoneField';
import EmailField from './Fields/EmailField';
import SelectField from './Fields/SelectField';
import TextAreaField from './Fields/TextAreaField';
import RangeSliderField from './Fields/RangeSliderField';
import MultiRowControl from './Fields/MultiRowControl';
import ToggleSwitch from './Fields/ToggleSwitch';
import ColorPicker from './Fields/ColorPicker';

import ActionButton from '../Common/ActionButton';
import Section from './Fields/Section';
import { DataContext } from '../../DataContext';
import Spinner from '../Common/Spinner';
import './FillOutForm.css';
import { useTranslation, Trans } from 'react-i18next';
import { camelCase } from 'lodash';
import { UserContext } from '../../contexts/UserContext';

import UserIcon from '../../assets/UserIcon.png';
import CatholicoreLogoSimple from '../../assets/CatholicoreLogoSimple.png';

const FillOutForm = ({
  fields,
  formId,
  showToast,
  initialData = null,
  responseId = null,
  onCancel,
  onFormSubmit,
  viewMode = false,
  groupId,
  autoSwitchToUpdate = false,
  // Conditionally render consent checkboxes
  showConsentCheckboxes = false,
}) => {
  const { t } = useTranslation();
  const {
    organizationData,
    fetchFormDetails,
    fetchCatalogs,
    submitFormResponse,
    updateResponse,
  } = useContext(DataContext);
  const { userGroups } = useContext(UserContext);

  // Parse date helper
  const parseDate = (value) => {
    if (!value) return null;
    const d = new Date(value);
    return isNaN(d.getTime()) ? null : d;
  };

  // Phone helper (make sure we format only if user actually enters something)
  const validateAndFormatPhone = (phone) => {
    if (!phone) return ''; // If no phone entered, return empty
    let cleaned = phone.replace(/[^0-9+]/g, '');
    if (cleaned.startsWith('+1')) {
      cleaned = cleaned.slice(2);
    } else if (cleaned.length === 11 && cleaned.startsWith('1')) {
      cleaned = cleaned.slice(1);
    }
    if (cleaned.length === 10) {
      return `+1${cleaned}`;
    }
    return null; // indicates invalid
  };

  // remove null/undefined
  const sanitizeData = (data) => {
    if (Array.isArray(data)) {
      return data.map(sanitizeData);
    } else if (data && typeof data === 'object') {
      const result = {};
      Object.keys(data).forEach((k) => {
        const val = sanitizeData(data[k]);
        if (val !== undefined) {
          result[k] = val;
        }
      });
      return result;
    }
    return data;
  };

  // Initialize local form state
  const initializeFormState = () => {
    const state = {};
    fields.forEach((field) => {
      if (field.type === 'Section') return;
      let existingVal = null;

      // Attempt to load from initialData
      if (initialData && Array.isArray(initialData.fields)) {
        const found = initialData.fields.find((f) => f.id === field.id);
        if (found) existingVal = found.value;
      }

      if (existingVal !== null && existingVal !== undefined) {
        switch (field.type) {
          case 'Checkbox':
            state[field.id] = existingVal || [];
            break;
          case 'MultiRowControl':
            state[field.id] = existingVal || [];
            break;
          case 'DatePicker':
          case 'TimePicker':
            state[field.id] = parseDate(existingVal);
            break;
          case 'RangeSlider':
            state[field.id] = Number(existingVal) || field.min || 0;
            break;
          case 'FileUpload':
          case 'ImagePicker':
            state[field.id] = existingVal;
            break;
          case 'ToggleSwitch':
            state[field.id] = !!existingVal;
            break;
          case 'ColorPicker':
            state[field.id] = existingVal || '#000000';
            break;
          default:
            state[field.id] = existingVal;
        }
      } else {
        // Otherwise use default from field definition
        switch (field.type) {
          case 'Checkbox':
            state[field.id] = field.options
              ? field.options.map((opt) => ({
                  name: opt.value || '',
                  completed: false,
                }))
              : [];
            break;
          case 'MultiRowControl':
            state[field.id] = [];
            break;
          case 'DatePicker':
          case 'TimePicker':
            state[field.id] = parseDate(field.value) || null;
            break;
          case 'RangeSlider':
            state[field.id] = Number(field.value) || field.min || 0;
            break;
          case 'FileUpload':
          case 'ImagePicker':
            state[field.id] = field.value || null;
            break;
          case 'ToggleSwitch':
            state[field.id] = !!field.value;
            break;
          case 'ColorPicker':
            state[field.id] = field.value || '#000000';
            break;
          default:
            state[field.id] = field.value || '';
        }
      }
    });
    return state;
  };

  const [formData, setFormData] = useState(initializeFormState());
  const [submitting, setSubmitting] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [catalogData, setCatalogData] = useState({});
  const [formBelongsToGroup, setFormBelongsToGroup] = useState(true);
  const [isFormLoading, setIsFormLoading] = useState(true);

  // NEW: Separate consents for SMS, Email, Terms
  const [consents, setConsents] = useState({
    sms: false,
    email: false,
    terms: false,
  });
  const [consentError, setConsentError] = useState(false);

  const [localResponseId, setLocalResponseId] = useState(responseId || null);

  const handleChange = (fieldId, val) => {
    if (viewMode) return;
    setFormData((prev) => ({ ...prev, [fieldId]: val }));
  };

  const handleFieldChange = (fieldId, val) => handleChange(fieldId, val);
  const handleValidation = (fieldId, errorMsg) => {
    if (viewMode) return;
    setFormErrors((prev) => ({ ...prev, [fieldId]: errorMsg }));
  };

  // Clear errors on fields/initialData changes
  useEffect(() => {
    setFormErrors({});
  }, [fields, initialData]);

  // Load catalogs for "Select" fields if needed
  useEffect(() => {
    const loadCatalogs = async () => {
      const catIds = fields
        .filter((f) => f.type === 'Select' && f.catalogId)
        .map((f) => f.catalogId);
      const uniqueIds = [...new Set(catIds)];
      const needed = uniqueIds.filter((id) => !catalogData[id]);
      if (!needed.length) return;
      try {
        const result = await fetchCatalogs(needed);
        setCatalogData((prev) => ({ ...prev, ...result }));
      } catch (err) {
        console.error('Error fetching catalogs:', err);
        showToast(t('fillOutForm.errorFetchingCatalogData'), 'error');
      }
    };
    loadCatalogs();
  }, [fields, catalogData]);

  // Check if form belongs to group
  useEffect(() => {
    const verifyFormAccess = async () => {
      if (!formId) {
        setIsFormLoading(false);
        return;
      }
      try {
        const fd = await fetchFormDetails(formId);
        if (fd) {
          const hasAccess = userGroups.some((g) => g.id === fd.groupId);
          if (fd.groupId !== organizationData.groupId && !hasAccess) {
            setFormBelongsToGroup(false);
            showToast(t('fillOutForm.accessDenied'), 'error');
          } else {
            setFormBelongsToGroup(true);
          }
        } else {
          setFormBelongsToGroup(false);
          showToast(t('fillOutForm.formNotFound'), 'error');
        }
      } catch (err) {
        console.error('Error fetching form details:', err);
        setFormBelongsToGroup(false);
        showToast(t('fillOutForm.errorFetchingFormDetails'), 'error');
      } finally {
        setIsFormLoading(false);
      }
    };
    verifyFormAccess();
  }, [formId]);

  if (isFormLoading) {
    return (
      <div className="loading-spinner-container">
        <Spinner size="60px" />
      </div>
    );
  }
  if (!formBelongsToGroup) {
    return <div className="access-denied">{t('fillOutForm.accessDeniedMessage')}</div>;
  }

  // Group fields in rows
  const groupFieldsIntoRows = (flds) => {
    const rowsArr = [];
    let currRow = [];
    flds.forEach((field) => {
      if (field.type === 'Section') {
        if (currRow.length) {
          rowsArr.push([...currRow]);
          currRow = [];
        }
        rowsArr.push([field]);
        return;
      }
      const size = field.size || 'full';
      if (size === 'half') {
        currRow.push(field);
        if (currRow.length === 2) {
          rowsArr.push([...currRow]);
          currRow = [];
        }
      } else {
        if (currRow.length) {
          rowsArr.push([...currRow]);
          currRow = [];
        }
        rowsArr.push([field]);
      }
    });
    if (currRow.length) rowsArr.push([...currRow]);
    return rowsArr;
  };

  // Filter out dynamic fields for new responses in membersForm (if needed)
  const dynamicFieldIds = ['communications', 'sendSMS', 'sendEmail', 'sendPushNotification'];
  const fieldsToRender =
    formId === 'membersForm' && showConsentCheckboxes && !localResponseId
      ? fields.filter((field) => !dynamicFieldIds.includes(field.id))
      : fields;
  const rows = groupFieldsIntoRows(fieldsToRender);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (viewMode) return;

    // 1) field-level errors
    const anyErrors = Object.values(formErrors).some(Boolean);
    if (anyErrors) {
      showToast(t('fillOutForm.fixErrorsBeforeSubmitting'), 'error');
      return;
    }

    // 2) required fields
    for (const f of fields) {
      if (f.type === 'Section' || !f.required) continue;
      const val = formData[f.id];

      if (f.type === 'Checkbox') {
        if (!val || !Array.isArray(val) || !val.some((o) => o.completed)) {
          showToast(t('fillOutForm.selectAtLeastOneOption', { label: f.label }), 'error');
          return;
        }
      } else if (
        f.type !== 'Checkbox' &&
        f.type !== 'MultiRowControl' &&
        f.type !== 'ToggleSwitch' &&
        (val === null || val === undefined || val === '')
      ) {
        showToast(t('fillOutForm.fillOutField', { label: f.label }), 'error');
        return;
      }
      if (f.type === 'MultiRowControl') {
        if (Array.isArray(val) && val.length === 0) {
          showToast(t('fillOutForm.addAtLeastOneRow', { label: f.label }), 'error');
          return;
        }
      }
      if ((f.type === 'DatePicker' || f.type === 'TimePicker') && !val) {
        showToast(t('fillOutForm.selectValueForField', { label: f.label }), 'error');
        return;
      }
      if (f.type === 'RangeSlider') {
        const { min, max } = f;
        if (typeof min === 'number' && typeof max === 'number') {
          if (val < min || val > max) {
            showToast(t('fillOutForm.fieldMustBeBetween', { label: f.label, min, max }), 'error');
            return;
          }
        }
      }
      if (f.type === 'ColorPicker') {
        const isHexColor = /^#([0-9A-F]{3}){1,2}$/i.test(val);
        if (!isHexColor) {
          showToast(t('fillOutForm.invalidColor', { label: f.label }), 'error');
          return;
        }
      }
    }

    // 3) Validate consents if they are being shown
    if (showConsentCheckboxes && !consents.terms) {
      setConsentError(true);
      showToast(t('fillOutForm.mustAgreeToTerms'), 'error');
      return;
    }

    // 4) phone fields (optional validation)
    const telFields = fields.filter((fld) => fld.type === 'Telephone');
    const updatedData = { ...formData };

    for (const fld of telFields) {
      const phoneVal = updatedData[fld.id]?.trim();
      if (phoneVal) {
        // If user entered something, validate/format it
        const validPhone = validateAndFormatPhone(phoneVal);
        if (!validPhone) {
          showToast(t('fillOutForm.invalidPhoneNumber', { label: fld.label }), 'error');
          return;
        }
        updatedData[fld.id] = validPhone;
      } else {
        // If user didn't enter a phone, leave it blank
        updatedData[fld.id] = '';
      }
    }

    // 5) mandatory profilePicture fallback
    const profilePicField = fields.find((fld) => fld.id === 'profilePicture' && fld.mandatory);
    if (profilePicField) {
      const picVal = updatedData[profilePicField.id];
      if (!picVal || (typeof picVal === 'string' && !picVal.trim())) {
        if (formId === 'membersForm') {
          updatedData[profilePicField.id] = UserIcon;
        } else if (formId === 'groupsForm') {
          updatedData[profilePicField.id] = CatholicoreLogoSimple;
        }
      }
    }

    setSubmitting(true);

    try {
      // build final fields array
      let finalFields = [];
      for (let i = 0; i < fields.length; i++) {
        const f = fields[i];
        if (f.type === 'Section') continue;
        let val = updatedData[f.id];

        // date/time to ISO
        if (f.type === 'DatePicker' || f.type === 'TimePicker') {
          if (val instanceof Date) {
            val = val.toISOString();
          }
        }
        if (f.type === 'RangeSlider') {
          val = Number(val) || f.min || 0;
        }
        if (f.type === 'ColorPicker') {
          val = val || '#000000';
        }
        if (f.type === 'Checkbox' && Array.isArray(val)) {
          val = val.map((opt) => ({
            name: opt.name || '',
            completed: !!opt.completed,
          }));
        }

        finalFields.push({
          id: f.id,
          label: f.label,
          type: f.type,
          order: i + 1,
          value: val !== undefined ? val : null,
          ...(f.type === 'RangeSlider' && {
            min: f.min !== undefined ? f.min : 0,
            max: f.max !== undefined ? f.max : 100,
          }),
        });
      }

      // 6) Force toggles if user consents to SMS or Email
      if (showConsentCheckboxes) {
        // If user checked SMS
        if (consents.sms) {
          const smsFieldIndex = finalFields.findIndex((f) => f.id === 'sendSMS');
          if (smsFieldIndex >= 0) {
            finalFields[smsFieldIndex].value = true;
          } else {
            finalFields.push({
              id: 'sendSMS',
              label: t('fillOutForm.sendSMS'),
              type: 'ToggleSwitch',
              order: finalFields.length + 1,
              value: true,
            });
          }
        }

        // If user checked Email
        if (consents.email) {
          const emailFieldIndex = finalFields.findIndex((f) => f.id === 'sendEmail');
          if (emailFieldIndex >= 0) {
            finalFields[emailFieldIndex].value = true;
          } else {
            finalFields.push({
              id: 'sendEmail',
              label: t('fillOutForm.sendEmail'),
              type: 'ToggleSwitch',
              order: finalFields.length + 1,
              value: true,
            });
          }
        }
      }

      const sanitizedFields = sanitizeData(finalFields);
      const responseBody = { fields: sanitizedFields };

      // Include consents in response if applicable
      if (showConsentCheckboxes) {
        responseBody.consents = consents;
      }

      const currentlyUpdating = !!localResponseId;
      if (!currentlyUpdating) {
        // If new record
        if (formId === 'membersForm') {
          responseBody.groups = [
            {
              groupId: groupId || organizationData.groupId,
              memberType: 'member',
              organizationId: organizationData.organizationId,
            },
          ];
        }
        const newDoc = await submitFormResponse(formId, responseBody);
        showToast(t('fillOutForm.responseSubmittedSuccessfully'), 'success');
        if (onFormSubmit) {
          onFormSubmit(newDoc?.id || null);
        }
        if (autoSwitchToUpdate && newDoc?.id) {
          setLocalResponseId(newDoc.id);
        }
      } else {
        // If updating existing
        await updateResponse(formId, localResponseId, responseBody, groupId || null);
        showToast(t('fillOutForm.responseUpdatedSuccessfully'), 'success');
        if (onFormSubmit) {
          onFormSubmit(localResponseId);
        }
      }

      setSubmitting(false);
    } catch (err) {
      console.error('Error submitting form:', err);
      if (localResponseId) {
        showToast(t('fillOutForm.errorUpdatingResponse'), 'error');
      } else {
        showToast(t('fillOutForm.errorSubmittingResponse'), 'error');
      }
      setSubmitting(false);
    }
  };

  const renderField = (field) => {
    if (field.type === 'Section') {
      return (
        <div key={field.id} className="form-field full-width">
          <Section label={t(field.id, field.label)} description={field.description} />
        </div>
      );
    }

    const isDisabled = viewMode;
    const commonProps = {
      label: t(field.id, field.label),
      name: field.name || field.id,
      value: formData[field.id],
      onChange: (val) => handleFieldChange(field.id, val),
      required: field.required,
      size: field.size,
      onValidate: (err) => handleValidation(field.id, err),
      length: field.length || null,
      error: formErrors[field.id] || '',
      disabled: isDisabled,
      readOnly: isDisabled,
    };

    let FieldComponent;
    switch (field.type) {
      case 'Text':
        FieldComponent = <TextField {...commonProps} />;
        break;
      case 'Number':
        FieldComponent = <NumberField {...commonProps} min={field.min} max={field.max} />;
        break;
      case 'DatePicker':
        FieldComponent = <DatePicker {...commonProps} />;
        break;
      case 'TimePicker':
        FieldComponent = <TimePicker {...commonProps} />;
        break;
      case 'ToggleSwitch':
        FieldComponent = (
          <div className="toggle-switch-field">
            <ToggleSwitch
              id={field.id}
              label={t(camelCase(field.label))}
              labelPosition="left"
              checked={!!formData[field.id]}
              onChange={(val) => handleFieldChange(field.id, val)}
              disabled={isDisabled}
              activeColor="#4CAF50"
            />
          </div>
        );
        break;
      case 'ImagePicker':
        FieldComponent = (
          <ImagePicker {...commonProps} responseId={localResponseId || 'temp'} />
        );
        break;
      case 'Checkbox': {
        let options = [];
        if (field.options) {
          options = field.options.map((opt) => ({
            label: t(opt.value, opt.label),
            value: opt.value,
          }));
        }
        FieldComponent = (
          <CheckboxField
            {...commonProps}
            options={options}
            onChange={(updated) => handleFieldChange(field.id, updated)}
          />
        );
        break;
      }
      case 'RadioButton':
        FieldComponent = <RadioButtonField {...commonProps} options={field.options} />;
        break;
      case 'Telephone':
        FieldComponent = <TelephoneField {...commonProps} />;
        break;
      case 'Email':
        FieldComponent = <EmailField {...commonProps} />;
        break;
      case 'Select': {
        let options = [];
        let isLoading = false;
        if (field.catalogId) {
          const cat = catalogData[field.catalogId];
          if (cat && cat.codes) {
            options = cat.codes
              .filter((c) => c.active !== false)
              .sort((a, b) => (a.order || 0) - (b.order || 0))
              .map((c) => ({
                label: t(c.value, c.description) || c.description,
                value: c.value,
              }));
          } else {
            isLoading = true;
          }
        } else if (field.options) {
          options = field.options;
        }
        FieldComponent = (
          <SelectField
            {...commonProps}
            options={options}
            placeholder={t('fillOutForm.selectAnOption')}
            disabled={isLoading || isDisabled}
          />
        );
        break;
      }
      case 'TextArea':
        FieldComponent = <TextAreaField {...commonProps} rows={4} />;
        break;
      case 'RangeSlider':
        FieldComponent = (
          <RangeSliderField
            {...commonProps}
            min={field.min}
            max={field.max}
            step={field.step}
            showValue={field.showValue}
          />
        );
        break;
      case 'MultiRowControl':
        FieldComponent = (
          <MultiRowControl
            {...commonProps}
            fields={formData[field.id] || []}
            rowFields={field.rowFields}
            onChange={(vals) => handleChange(field.id, vals)}
            onAddRow={() => {
              if (isDisabled) return;
              const newRow = { id: `row_${Date.now()}` };
              field.rowFields.forEach((rf) => {
                newRow[rf.id] = '';
              });
              const existing = formData[field.id] || [];
              handleChange(field.id, [...existing, newRow]);
            }}
            onRemoveRow={(rowId) => {
              if (isDisabled) return;
              const existing = formData[field.id] || [];
              const updated = existing.filter((row) => row.id !== rowId);
              handleChange(field.id, updated);
            }}
          />
        );
        break;
      case 'ColorPicker':
        FieldComponent = <ColorPicker {...commonProps} />;
        break;
      default:
        FieldComponent = null;
    }
    return (
      <div
        key={field.id}
        className={`form-field ${field.size === 'half' ? 'half-width' : 'full-width'}`}
      >
        {FieldComponent}
      </div>
    );
  };

  const isUpdating = !!localResponseId;
  const submitButtonLabel = isUpdating ? t('fillOutForm.update') : t('fillOutForm.submit');

  return (
    <form className="fill-out-form" onSubmit={handleSubmit}>
      {rows.map((row, idx) => (
        <div className="form-row" key={idx}>
          {row.map((field) => renderField(field))}
        </div>
      ))}

      {/* Render consent checkboxes only for new submissions */}
      {showConsentCheckboxes && !isUpdating && (
        <div className={`consent-section ${consentError ? 'consent-error' : ''}`}>
          <Section label={t('fillOutForm.consentSectionLabel')} description="" />

          {/* SMS Consent */}
          <CheckboxField
            name="smsConsent"
            options={[
              {
                label: (
                  <Trans i18nKey="fillOutForm.smsConsentText">
                    {/* 
                      The <0> and <1> placeholders here refer to 
                      the anchor tags for Terms & Privacy. 
                      You can also do it inline if you prefer.
                    */}
                    <a
                      href="https://catholicore.com/policies?policy=privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                    <a
                      href="https://catholicore.com/policies?policy=terms"
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  </Trans>
                ),
                value: 'sms',
              },
            ]}
            value={
              consents.sms
                ? [{ name: 'sms', completed: true }]
                : [{ name: 'sms', completed: false }]
            }
            onChange={(updatedOptions) => {
              const isChecked = updatedOptions[0]?.completed;
              setConsents((prev) => ({ ...prev, sms: isChecked }));
            }}
            required={false}
            layout="vertical"
          />

          {/* Email Consent */}
          <CheckboxField
            name="emailConsent"
            options={[
              {
                label: t('fillOutForm.emailConsentText'),
                value: 'email',
              },
            ]}
            value={
              consents.email
                ? [{ name: 'email', completed: true }]
                : [{ name: 'email', completed: false }]
            }
            onChange={(updatedOptions) => {
              const isChecked = updatedOptions[0]?.completed;
              setConsents((prev) => ({ ...prev, email: isChecked }));
            }}
            required={false}
            layout="vertical"
          />

          {/* Terms & Privacy Acknowledgment */}
          <CheckboxField
            label={t('fillOutForm.termsConsentLabel')}
            name="termsConsent"
            options={[
              {
                label: (
                  <Trans i18nKey="fillOutForm.termsConsentText">
                    <a
                      href="https://catholicore.com/policies?policy=terms"
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                    <a
                      href="https://catholicore.com/policies?policy=privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  </Trans>
                ),
                value: 'terms',
              },
            ]}
            value={
              consents.terms
                ? [{ name: 'terms', completed: true }]
                : [{ name: 'terms', completed: false }]
            }
            onChange={(updatedOptions) => {
              const isChecked = updatedOptions[0]?.completed;
              setConsentError(false);
              setConsents((prev) => ({ ...prev, terms: isChecked }));
            }}
            required={true}
            layout="vertical"
          />
        </div>
      )}

      {rows.length === 0 && (
        <div className="info-message">{t('fillOutForm.buildFormToFill')}</div>
      )}

      {!viewMode && rows.length > 0 && (
        <div className="form-actions">
          <ActionButton
            type="submit"
            label={submitButtonLabel}
            text={submitButtonLabel}
            icon={isUpdating ? 'faSave' : 'faPaperPlane'}
            colorType="primary"
            disabled={submitting}
            className="submit-button full-width-button"
          />
          {onCancel && (
            <ActionButton
              type="button"
              label={t('fillOutForm.cancel')}
              text={t('fillOutForm.cancel')}
              icon="faTimes"
              colorType="secondary"
              disabled={submitting}
              className="cancel-button full-width-button"
              onClick={onCancel}
            />
          )}
        </div>
      )}
    </form>
  );
};

FillOutForm.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  formId: PropTypes.string.isRequired,
  showToast: PropTypes.func.isRequired,
  initialData: PropTypes.shape({
    id: PropTypes.string,
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired,
      })
    ),
  }),
  responseId: PropTypes.string,
  onCancel: PropTypes.func,
  onFormSubmit: PropTypes.func,
  viewMode: PropTypes.bool,
  groupId: PropTypes.string,
  autoSwitchToUpdate: PropTypes.bool,
  showConsentCheckboxes: PropTypes.bool,
};

export default FillOutForm;
